module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://exerciciosresolvidos.com.br/","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Exercícios Resolvidos","short_name":"ExercRes","start_url":"/","background_color":"#2C3E50","theme_color":"#2C3E50","display":"minimal-ui","icon":"src/images/fav-icon-exercicios-resolvidos.png","defaults":{"formats":["auto","webp"],"placeholder":"dominantColor","quality":50,"breakpoints":[750,1080,1366,1920],"backgroundColor":"transparent","tracedSVGOptions":{},"blurredOptions":{},"jpgOptions":{},"pngOptions":{},"webpOptions":{},"avifOptions":{}},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"27835d6c01854625ff3ba41a06befde2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
