exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exercicio-js": () => import("./../../../src/pages/exercicio.js" /* webpackChunkName: "component---src-pages-exercicio-js" */),
  "component---src-pages-exercicio-novo-js": () => import("./../../../src/pages/exercicioNovo.js" /* webpackChunkName: "component---src-pages-exercicio-novo-js" */),
  "component---src-pages-exercicios-de-acentuacao-grafica-com-gabarito-comentado-js": () => import("./../../../src/pages/exercicios-de-acentuacao-grafica-com-gabarito-comentado.js" /* webpackChunkName: "component---src-pages-exercicios-de-acentuacao-grafica-com-gabarito-comentado-js" */),
  "component---src-pages-exercicios-de-administracao-geral-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-administracao-geral-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-administracao-geral-com-gabarito-js" */),
  "component---src-pages-exercicios-de-algoritmos-para-iniciantes-com-respostas-js": () => import("./../../../src/pages/exercicios-de-algoritmos-para-iniciantes-com-respostas.js" /* webpackChunkName: "component---src-pages-exercicios-de-algoritmos-para-iniciantes-com-respostas-js" */),
  "component---src-pages-exercicios-de-banco-de-dados-com-repostas-e-resolucao-js": () => import("./../../../src/pages/exercicios-de-banco-de-dados-com-repostas-e-resolucao.js" /* webpackChunkName: "component---src-pages-exercicios-de-banco-de-dados-com-repostas-e-resolucao-js" */),
  "component---src-pages-exercicios-de-comparativo-em-ingles-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-comparativo-em-ingles-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-comparativo-em-ingles-com-gabarito-js" */),
  "component---src-pages-exercicios-de-crase-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-crase-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-crase-com-gabarito-js" */),
  "component---src-pages-exercicios-de-espanhol-sobre-animais-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-espanhol-sobre-animais-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-espanhol-sobre-animais-com-gabarito-js" */),
  "component---src-pages-exercicios-de-figuras-de-linguagem-resolvidos-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-figuras-de-linguagem-resolvidos-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-figuras-de-linguagem-resolvidos-com-gabarito-js" */),
  "component---src-pages-exercicios-de-fracao-com-respostas-js": () => import("./../../../src/pages/exercicios-de-fracao-com-respostas.js" /* webpackChunkName: "component---src-pages-exercicios-de-fracao-com-respostas-js" */),
  "component---src-pages-exercicios-de-inteligencia-artificial-resolvidos-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-inteligencia-artificial-resolvidos-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-inteligencia-artificial-resolvidos-com-gabarito-js" */),
  "component---src-pages-exercicios-de-interpretacao-de-texto-com-respostas-js": () => import("./../../../src/pages/exercicios-de-interpretacao-de-texto-com-respostas.js" /* webpackChunkName: "component---src-pages-exercicios-de-interpretacao-de-texto-com-respostas-js" */),
  "component---src-pages-exercicios-de-orientacao-a-objetos-js": () => import("./../../../src/pages/exercicios-de-orientacao-a-objetos.js" /* webpackChunkName: "component---src-pages-exercicios-de-orientacao-a-objetos-js" */),
  "component---src-pages-exercicios-de-principios-da-administracao-publica-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-principios-da-administracao-publica-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-principios-da-administracao-publica-com-gabarito-js" */),
  "component---src-pages-exercicios-de-probabilidade-resolvidos-com-gabarito-e-passo-a-passo-dos-calculos-js": () => import("./../../../src/pages/exercicios-de-probabilidade-resolvidos-com-gabarito-e-passo-a-passo-dos-calculos.js" /* webpackChunkName: "component---src-pages-exercicios-de-probabilidade-resolvidos-com-gabarito-e-passo-a-passo-dos-calculos-js" */),
  "component---src-pages-exercicios-de-pronomes-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-pronomes-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-pronomes-com-gabarito-js" */),
  "component---src-pages-exercicios-de-proposicoes-simples-e-compostas-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-proposicoes-simples-e-compostas-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-proposicoes-simples-e-compostas-com-gabarito-js" */),
  "component---src-pages-exercicios-de-regra-de-tres-simples-resolvidos-e-comentados-js": () => import("./../../../src/pages/exercicios-de-regra-de-tres-simples-resolvidos-e-comentados.js" /* webpackChunkName: "component---src-pages-exercicios-de-regra-de-tres-simples-resolvidos-e-comentados-js" */),
  "component---src-pages-exercicios-de-requisitos-de-software-funcionais-e-nao-funcionais-js": () => import("./../../../src/pages/exercicios-de-requisitos-de-software-funcionais-e-nao-funcionais.js" /* webpackChunkName: "component---src-pages-exercicios-de-requisitos-de-software-funcionais-e-nao-funcionais-js" */),
  "component---src-pages-exercicios-de-sql-resolvidos-basicos-e-avancados-js": () => import("./../../../src/pages/exercicios-de-sql-resolvidos-basicos-e-avancados.js" /* webpackChunkName: "component---src-pages-exercicios-de-sql-resolvidos-basicos-e-avancados-js" */),
  "component---src-pages-exercicios-de-superlativo-em-ingles-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-superlativo-em-ingles-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-superlativo-em-ingles-com-gabarito-js" */),
  "component---src-pages-exercicios-de-tabela-verdade-resolvidos-com-gabarito-js": () => import("./../../../src/pages/exercicios-de-tabela-verdade-resolvidos-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-de-tabela-verdade-resolvidos-com-gabarito-js" */),
  "component---src-pages-exercicios-resolvidos-de-fisica-sobre-leis-de-newton-com-calculos-js": () => import("./../../../src/pages/exercicios-resolvidos-de-fisica-sobre-leis-de-newton-com-calculos.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-de-fisica-sobre-leis-de-newton-com-calculos-js" */),
  "component---src-pages-exercicios-resolvidos-de-genetica-com-respostas-e-gabarito-js": () => import("./../../../src/pages/exercicios-resolvidos-de-genetica-com-respostas-e-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-de-genetica-com-respostas-e-gabarito-js" */),
  "component---src-pages-exercicios-resolvidos-de-juros-compostos-com-passo-a-passo-dos-calculos-js": () => import("./../../../src/pages/exercicios-resolvidos-de-juros-compostos-com-passo-a-passo-dos-calculos.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-de-juros-compostos-com-passo-a-passo-dos-calculos-js" */),
  "component---src-pages-exercicios-resolvidos-de-juros-simples-js": () => import("./../../../src/pages/exercicios-resolvidos-de-juros-simples.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-de-juros-simples-js" */),
  "component---src-pages-exercicios-resolvidos-de-matematica-financeira-js": () => import("./../../../src/pages/exercicios-resolvidos-de-matematica-financeira.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-de-matematica-financeira-js" */),
  "component---src-pages-exercicios-resolvidos-de-matrizes-js": () => import("./../../../src/pages/exercicios-resolvidos-de-matrizes.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-de-matrizes-js" */),
  "component---src-pages-exercicios-resolvidos-de-porcentagem-js": () => import("./../../../src/pages/exercicios-resolvidos-de-porcentagem.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-de-porcentagem-js" */),
  "component---src-pages-exercicios-resolvidos-sobre-animais-em-ingles-js": () => import("./../../../src/pages/exercicios-resolvidos-sobre-animais-em-ingles.js" /* webpackChunkName: "component---src-pages-exercicios-resolvidos-sobre-animais-em-ingles-js" */),
  "component---src-pages-exercicios-sobre-a-rosa-dos-ventos-com-gabarito-js": () => import("./../../../src/pages/exercicios-sobre-a-rosa-dos-ventos-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-sobre-a-rosa-dos-ventos-com-gabarito-js" */),
  "component---src-pages-exercicios-sobre-atos-administrativos-com-gabarito-js": () => import("./../../../src/pages/exercicios-sobre-atos-administrativos-com-gabarito.js" /* webpackChunkName: "component---src-pages-exercicios-sobre-atos-administrativos-com-gabarito-js" */),
  "component---src-pages-exercicios-sobre-o-feudalismo-com-gabarito-para-enem-e-vestibular-js": () => import("./../../../src/pages/exercicios-sobre-o-feudalismo-com-gabarito-para-enem-e-vestibular.js" /* webpackChunkName: "component---src-pages-exercicios-sobre-o-feudalismo-com-gabarito-para-enem-e-vestibular-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lista-de-exercicios-de-html-basico-resolvidos-js": () => import("./../../../src/pages/lista-de-exercicios-de-html-basico-resolvidos.js" /* webpackChunkName: "component---src-pages-lista-de-exercicios-de-html-basico-resolvidos-js" */),
  "component---src-pages-lista-de-exercicios-de-javascript-resolvidos-js": () => import("./../../../src/pages/lista-de-exercicios-de-javascript-resolvidos.js" /* webpackChunkName: "component---src-pages-lista-de-exercicios-de-javascript-resolvidos-js" */),
  "component---src-pages-perguntasrespostas-js": () => import("./../../../src/pages/perguntasrespostas.js" /* webpackChunkName: "component---src-pages-perguntasrespostas-js" */),
  "component---src-pages-perguntasrespostas-novo-js": () => import("./../../../src/pages/perguntasrespostasNovo.js" /* webpackChunkName: "component---src-pages-perguntasrespostas-novo-js" */),
  "component---src-pages-questoes-e-exercicios-por-temas-e-disciplinas-em-ordem-alfabetica-js": () => import("./../../../src/pages/questoes-e-exercicios-por-temas-e-disciplinas-em-ordem-alfabetica.js" /* webpackChunkName: "component---src-pages-questoes-e-exercicios-por-temas-e-disciplinas-em-ordem-alfabetica-js" */),
  "component---src-pages-regra-de-tres-composta-exercicios-resolvidos-js": () => import("./../../../src/pages/regra-de-tres-composta-exercicios-resolvidos.js" /* webpackChunkName: "component---src-pages-regra-de-tres-composta-exercicios-resolvidos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-todasperguntasrespostas-js": () => import("./../../../src/pages/todasperguntasrespostas.js" /* webpackChunkName: "component---src-pages-todasperguntasrespostas-js" */)
}

